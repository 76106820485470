export enum Size {
  Thumb,
  Tiny,
  Small,
  Medium,
  Large,
  Huge,
  Gigantic,
  Original,
}

type SizesType = { [key: number]: number };

export const getSizes = (): SizesType => ({
  [Size.Thumb]: 60,
  [Size.Tiny]: 320,
  [Size.Small]: 640,
  [Size.Medium]: 960,
  [Size.Large]: 1280,
  [Size.Huge]: 1920,
  [Size.Gigantic]: 3200,
});

export function getPresetFromWidth(estimatedWidth: number, availableSizes: SizesType): Size {
  return parseInt(Object.keys(availableSizes).find(sizeKey => availableSizes[sizeKey] >= estimatedWidth), 10);
}
