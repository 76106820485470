import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { URLX, relativeUrl } from '@avensia/scope';
import { getSizes, Size as Preset } from '../image-sizes';
import NoImage from './noimage.svg';
import * as style from '../Style';

const ImageTransition = styled.img({
  maxWidth: '100%',
  ...style.transition({ property: 'opacity' }),
});

export { Size as Preset } from '../image-sizes';

enum Status {
  Pending,
  Rejected,
  Fulfilled,
}

type PropType = StyledProps &
  React.ImgHTMLAttributes<HTMLImageElement> & {
    preset?: Preset;
    pendingPreview?: boolean;
  };

type StateType = {
  status?: Status;
};

export default styled(
  class Img extends React.Component<PropType, StateType> {
    mounted: boolean;
    isCached: boolean;
    currentLoad: Promise<HTMLImageElement>;
    src: string | null = null;
    constructor(props: PropType) {
      super(props);
      this.src = this.srcUrl();

      this.isCached = !!props.src && !!findBrowserCachedResource(this.src);
      this.state = {
        status: props.src ? (this.isCached ? Status.Fulfilled : Status.Pending) : Status.Rejected,
      };
    }
    componentWillMount() {
      this.mounted = true;

      if (!this.isCached && this.src) {
        this.loadResource(this.src);
      }
    }
    componentWillReceiveProps(nextProps: PropType) {
      if (this.props.src !== nextProps.src || this.props.preset !== nextProps.preset) {
        if (this.props.src !== nextProps.src) {
          this.src = this.srcUrl(nextProps.src, nextProps.preset);
        }

        if (this.src) {
          this.loadResource(this.src);
        }
      }
    }
    componentDidUpdate(prevProps: PropType) {
      if (!this.props.src) {
        this.status(Status.Rejected);
      } else if (this.props.src !== prevProps.src || this.props.preset !== prevProps.preset) {
        if (!this.isCached) {
          this.status(Status.Pending);
        }
      }
    }
    componentWillUnmount() {
      this.mounted = false;
    }
    async loadResource(src: string) {
      const originalSrc = this.props.src;
      const promise = (this.currentLoad = load(src));

      try {
        const image = await promise;
        rememberBrowserCachedResource(image, originalSrc);
        if (this.mounted && this.currentLoad === promise) {
          this.status(Status.Fulfilled);
        }
      } catch (e) {
        if (this.mounted && this.currentLoad === promise) {
          this.status(Status.Rejected);
        }
      }
    }
    status(status: Status) {
      if (this.state.status !== status) {
        this.setState({ status });
      }
    }
    srcUrl(src = this.props.src, preset = this.props.preset, quality?: number): string {
      if (src) {
        const url = new URLX(src);

        if (typeof preset === 'number') {
          url.searchParams.set('w', String(sizes[preset]));
          url.searchParams.set('h', String(sizes[preset]));
          url.searchParams.set('mode', 'max');
        }

        if (quality) {
          url.searchParams.set('quality', String(quality));
        }

        return relativeUrl(url);
      }

      return null;
    }
    checkIfCachedByBrowser = (image: HTMLImageElement) => (this.isCached = assumeCachedByBrowser(image));
    render() {
      if (this.src) {
        const { status } = this.state;

        const { compose, preset, pendingPreview, ...restProps } = this.props;

        if (status === Status.Fulfilled) {
          return (
            <ImageTransition {...restProps} src={this.src} innerRef={this.checkIfCachedByBrowser} css={compose()} />
          );
        }

        if (this.src) {
          const cache = findBrowserCachedResource(this.props.src);

          if (cache) {
            return (
              <ImageTransition {...restProps} src={cache.src} innerRef={this.checkIfCachedByBrowser} css={compose()} />
            );
          }

          if (status === Status.Pending) {
            return pendingPreview ? (
              <ImageTransition
                {...restProps}
                src={this.srcUrl(this.src, Preset.Thumb)}
                innerRef={this.checkIfCachedByBrowser}
                css={compose()}
              />
            ) : (
              <ImageTransition
                {...restProps}
                src={this.src}
                innerRef={this.checkIfCachedByBrowser}
                css={compose({
                  opacity: 0,
                })}
              />
            );
          }
        }
      }

      return <NoImage className={this.props.className} style={this.props.style} width="100%" height="100%" />;
    }
  },
);

function load(src: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    Object.assign(image, {
      onload: () => resolve(image),
      onerror: () => reject(),
      src,
    });

    if (image.complete) {
      if (image.naturalWidth > 0) {
        resolve(image);
      } else {
        reject();
      }
    }
  });
}

const sizes = getSizes();

type ResourceType = {
  width: number;
  height: number;
  src: string;
};

const browserCachedResources: { [src: string]: ResourceType } = {};

function findBrowserCachedResource(originalSrc: string) {
  return browserCachedResources[originalSrc];
}

function rememberBrowserCachedResource(image: HTMLImageElement, originalSrc: string) {
  // Firefox has an ugly tendency to report naturalWidth as 0 when a SW is active.
  // But if we wait just a little bit, the sizes have been calculated. Broken
  // images should be uncommon, so we can afford the setTimeout here
  requestAnimationFrame(() => {
    if (image && assumeCachedByBrowser(image)) {
      const cached = findBrowserCachedResource(originalSrc);
      if (!cached || image.naturalWidth > cached.width) {
        browserCachedResources[originalSrc] = {
          width: image.naturalWidth,
          height: image.naturalHeight,
          src: image.currentSrc,
        };
      }
    }
  });
}

function assumeCachedByBrowser(image: HTMLImageElement) {
  if (!image) {
    return false;
  }
  const url = new URLX(image.src);

  // Firefox and IE has a bug where svg reports 0 for natural size in some cases
  if (/\.svg/.test(url.pathname)) {
    return false;
  }
  return image.complete && image.naturalWidth > 0;
}
